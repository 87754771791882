"use client";

import React, { useState, useEffect } from "react";
import YourScreenerTab from "./tabs/YourScreenerTab";
import StocksInFocus from "./tabs/StocksInFocus";
import MostPopularTab from "./tabs/MostPopularTab";

export const ScreenerHeader: React.FC = () => {
  const [activeTab, setActiveTab] = useState("mostPopular");
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [activeTab]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case "yourScreener":
        return <YourScreenerTab  />;
      case "stocksInFocus":
        return <StocksInFocus key={key} />;
      default:
        return <MostPopularTab />;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h2 className="text-2xl font-semibold mb-4 sm:mb-0">Stock Screener</h2>
        <button className="bg-[#FFA500] hover:bg-[#FF8C00] text-white px-4 py-2 rounded w-full sm:w-auto">
          Create Screener
        </button>
      </div>

      <div className="flex flex-wrap gap-4 mb-6">
        <button
          className={`pb-2 ${
            activeTab === "mostPopular"
              ? "text-[#0093FF] border-b-2 border-[#0093FF]"
              : "text-gray-600"
          }`}
          onClick={() => {
            window.location.reload()
            return setActiveTab("mostPopular");
          }}
        >
          Most Popular
        </button>
        <button
          className={`pb-2 ${
            activeTab === "yourScreener"
              ? "text-[#0093FF] border-b-2 border-[#0093FF]"
              : "text-gray-600"
          }`}
          onClick={() => {
            return setActiveTab("yourScreener");
          }}
        >
          Your Screener
        </button>
        <button
          className={`pb-2 ${
            activeTab === "stocksInFocus"
              ? "text-[#0093FF] border-b-2 border-[#0093FF]"
              : "text-gray-600"
          }`}
          onClick={() => setActiveTab("stocksInFocus")}
        >
          Stocks in focus
        </button>
      </div>

      <div className="bg-gray-100 rounded p-4">{renderActiveTab()}</div>
    </div>
  );
};

export default ScreenerHeader;
