import React from "react";

const YourScreenerTab: React.FC = () => {
  return (
    <div className="text-center">
      <h3 className="text-xl font-semibold text-gray-700">Your Screener</h3>
      <p className="text-gray-500">Your saved screeners will appear here.</p>
    </div>
  );
};

export default YourScreenerTab;
