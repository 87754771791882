"use client";

import React, { useState, useEffect } from "react";
import { Download, FileText } from 'lucide-react';
import { LoadingSpinner } from '../../pages/Home/components/ui/LoadingSpinner';
import { ErrorMessage } from '../../pages/Home/components/ui/ErrorMessage';


const ITEMS_PER_PAGE = 10;

interface IpoData {
  symbol: string;
  name: string;
  status: string;
  is_sme: boolean;
  additional_text: string;
  min_price: number | null;
  max_price: number | null;
  issue_price: number | null;
  listing_gains: number | null;
  listing_price: number | null;
  bidding_start_date: string | null;
  bidding_end_date: string | null;
  listing_date: string | null;
  lot_size: number | null;
  document_url: string | null;
}

interface ApiResponse {
  status: string;
  data: [{
    upcoming: IpoData[];
    listed: IpoData[];
    active: IpoData[];
    closed: IpoData[];
  }];
}

export const Ipos: React.FC = () => {
  const [data, setData] = useState<ApiResponse["data"][0] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [activeTab, setActiveTab] = useState<"upcoming" | "listed" | "active" | "closed">("upcoming");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.screeners.market/api/v1/market/ipo");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result: ApiResponse = await response.json();
      setData(result.data[0]);
      setLoading(false);
    } catch (error) {
      setError(error as Error);
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!data) return <ErrorMessage message="No data available" />;

  const currentData = data[activeTab];
  const totalPages = Math.ceil(currentData.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = currentData.slice(startIndex, endIndex);

  const handleDownloadCSV = () => {
    const timestamp = new Date().toISOString().split("T")[0];
    const filename = `IPO_${activeTab}_${timestamp}.csv`;
    const csvContent = [
      ["Symbol", "Name", "Status", "Is SME", "Additional Text", "Min Price", "Max Price", "Issue Price", "Listing Price", "Listing Gains", "Bidding Start Date", "Bidding End Date", "Listing Date", "Lot Size", "Document URL"],
      ...currentData.map(item => [
        item.symbol,
        item.name,
        item.status,
        item.is_sme.toString(),
        item.additional_text,
        item.min_price?.toString() ?? "N/A",
        item.max_price?.toString() ?? "N/A",
        item.issue_price?.toString() ?? "N/A",
        item.listing_price?.toString() ?? "N/A",
        item.listing_gains ? item.listing_gains.toFixed(2) + "%" : "N/A",
        item.bidding_start_date ?? "N/A",
        item.bidding_end_date ?? "N/A",
        item.listing_date ?? "N/A",
        item.lot_size?.toString() ?? "N/A",
        item.document_url ?? "N/A"
      ])
    ].map(row => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        buttons.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i
                ? "bg-blue-600 text-white"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            {i}
          </button>
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        buttons.push(
          <span key={i} className="px-3 py-1">
            ...
          </span>
        );
      }
    }
    return buttons;
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-6">
          <h1 className="text-2xl font-semibold mb-6">IPO Screener</h1>
          {/* Controls */}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 mb-6">
            <div className="flex space-x-2 p-2 bg-slate-100 rounded-lg">
              {(["upcoming", "listed", "active", "closed"] as const).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 rounded-md ${
                    activeTab === tab
                      ? "bg-blue-100 text-blue-600"
                      : "text-gray-600 hover:bg-gray-50"
                  }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            <button
              onClick={handleDownloadCSV}
              className="px-4 py-2 bg-green-50 text-green-600 hover:bg-green-100 rounded flex items-center"
            >
              <Download className="w-4 h-4 mr-2" />
              Download CSV
            </button>
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Symbol
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price Range
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Issue Price
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Listing Price
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Listing Gains
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    SME
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Listing Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600">
                      {item.symbol}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.status}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.min_price && item.max_price
                        ? `₹${item.min_price} - ₹${item.max_price}`
                        : "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.issue_price ? `₹${item.issue_price}` : "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.listing_price ? `₹${item.listing_price}` : "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {item.listing_gains ? (
                        <span className={item.listing_gains >= 0 ? "text-green-600" : "text-red-600"}>
                          {item.listing_gains.toFixed(2)}%
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.is_sme ? "Yes" : "No"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.listing_date || "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.document_url ? (
                        <a
                          href={item.document_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800"
                          title="Download PDF"
                        >
                          <FileText className="w-5 h-5" />
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex items-center justify-between mt-4 px-6 py-3 bg-gray-50">
              <div className="text-sm text-gray-600">
                Showing {startIndex + 1}-
                {Math.min(endIndex, currentData.length)} of {currentData.length}
              </div>
              <div className="flex space-x-2">{renderPaginationButtons()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

