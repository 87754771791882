import React from 'react';
import { Clock } from 'lucide-react';

export const BacktestTab: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center py-16">
      <Clock className="w-16 h-16 text-gray-400 mb-4" />
      <h3 className="text-xl font-semibold text-gray-700 mb-2">Backtest Feature Coming Soon</h3>
      <p className="text-gray-500 text-center max-w-md">
        We're working hard to bring you powerful backtesting capabilities.
        Stay tuned for updates!
      </p>
    </div>
  );
};