import React from 'react';
import { ScreenerCategory as ScreenerCategoryType } from '../../types/screener';
import { ScreenerCategory } from './ScreenerCategory';

interface ScreenerListProps {
  categories: ScreenerCategoryType[];
}

export const ScreenerList: React.FC<ScreenerListProps> = ({ categories }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
    {categories.map((category) => (
      <ScreenerCategory
        key={category.name}
        title={category.name}
        screeners={category.screeners}
      />
    ))}
  </div>
);