import React, { useState } from 'react'
import ScreenerContent from './components/Screener-content';

import Sidebar from './components/Sidebar';
import Header from './components/Header';
import DrawerContent from './components/DrawerContent';


const Dashboard: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState('Stocks Screener')

  const renderContent = () => {
    switch (activeMenu) {
      case 'Stocks Screener':
        return <ScreenerContent />
      case 'Forex Screener':
      case 'Crypto Screener':
      case 'Explore Screeners':
      case 'Candle Stick Pattern Screener':
      case 'Chart Pattern Screener':
      case 'Indicator Screener':
      case 'Harmonic Pattern Screener':
      case 'Fundamental Screener':
      case 'Home':
      case 'Products':
      case 'Company':
      case 'Blog':
        return <DrawerContent title={activeMenu} />
      default:
        return <DrawerContent title="Page Not Found" />
    }
  }

  return (
    <div className="flex h-screen bg-[#F8FAFC]">
      <Sidebar activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="flex-1 overflow-auto p-6">
          {renderContent()}
        </main>
      </div>
    </div>
  )
}

export default Dashboard

