import React, { useEffect, useState } from "react";
import { Clock } from "lucide-react";
import { useUserContext } from "../../../../../context/UserContext";

const MostPopularTab: React.FC = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  // Check login status on component mount
  useEffect(() => {
    const loginStatus = localStorage.getItem("isUserLogin");
    if (loginStatus === "true") {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, []);
  return (
    <div className="flex items-center justify-center py-10 bg-gray-100 rounded">
      {isUserLoggedIn ? (
        <div>
          <div className="flex flex-col items-center justify-center py-8">
            <Clock className="w-10 h-10 text-gray-400 mb-4" />
            <h3 className="text-xl font-semibold text-gray-700 mb-2">
              Feature Coming Soon
            </h3>
          </div>
        </div>
      ) : (
        <div className="text-center flex flex-col items-center space-y-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-8 h-8 text-blue-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125-1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
            />
          </svg>
          <div className="flex flex-col items-center">
            <a href="#" className="text-[#0093FF]">
              Sign in
            </a>
            <span className="text-gray-600">to view your saved screeners</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MostPopularTab;
