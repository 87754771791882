import React from "react";
import {
  Home,
  TrendingUp,
  RefreshCw,
  Bitcoin,
  Compass,
  CandlestickChart,
  LineChart,
  BarChart2,
  PieChart,
  Package,
  Building2,
  BookOpen,
  Twitter,
  Facebook,
  Instagram,
  Youtube,
} from "lucide-react";
import { Button } from "../../../components/ui/button";

interface SidebarProps {
  activeMenu: string;
  setActiveMenu: (menu: string) => void;
}

const navigation = {
  trade: [
    { name: "Stocks Screener", icon: TrendingUp },
    { name: "Forex Screener", icon: RefreshCw },
    { name: "Crypto Screener", icon: Bitcoin },
  ],
  screeners: [
    { name: "Explore Screeners", icon: Compass },
    { name: "Candle Stick Screener", icon: CandlestickChart },
    { name: "Chart Pattern Screener", icon: LineChart },
    { name: "Indicator Screener", icon: BarChart2 },
    { name: "Harmonic Pattern Screener", icon: PieChart },
    { name: "Fundamental Screener", icon: Package },
  ],
  community: [
    { name: "Home", icon: Home },
    { name: "Products", icon: Package },
    { name: "Company", icon: Building2 },
    { name: "Blog", icon: BookOpen },
  ],
};

const Sidebar: React.FC<SidebarProps> = ({ activeMenu, setActiveMenu }) => {
  return (
    <div className="w-64 bg-[#F4F9FF] border-r flex flex-col">
      <div className="p-6">
        <h1 className="text-xl font-bold flex items-center gap-2">
          <TrendingUp className="h-6 w-6 text-[#2C82FF]" />
          ScreenerMarket
        </h1>
      </div>
      <Button
        variant="outline"
        className="mx-6 mb-6 bg-[#2C82FF] text-white hover:bg-[#2C82FF]/90"
        size="lg"
      >
        + New Screener
      </Button>
      <nav className="flex-1 px-4">
        <div className="space-y-8">
          {/* Trade Section */}
          <div>
            <h3 className="px-3 text-sm font-medium text-gray-500">TRADE</h3>
            <div className="mt-2 space-y-1">
              {navigation.trade.map((item) => (
                <Button
                  key={item.name}
                  variant="ghost"
                  className={`w-full flex items-center justify-start space-x-3 ${
                    item.name === activeMenu ? "text-[#2C82FF] font-semibold" : "text-gray-700"
                  }`}
                  onClick={() => setActiveMenu(item.name)}
                >
                  <item.icon
                    className={`h-5 w-5 ${
                      item.name === activeMenu ? "text-[#2C82FF]" : "text-gray-700"
                    }`}
                  />
                <span className={`text-xs ${
                    item.name === activeMenu ? "text-[#2C82FF] font-semibold" : "text-gray-700"
                  }`}>{item.name}</span>
                </Button>
              ))}
            </div>
          </div>

          {/* Screeners Section */}
          <div>
            <h3 className="px-3 text-sm font-medium text-gray-500">SCREENERS</h3>
            <div className="mt-2 space-y-1">
              {navigation.screeners.map((item) => (
                <Button
                  key={item.name}
                  variant="ghost"
                  className={`w-full flex items-center justify-start space-x-3 ${
                    item.name === activeMenu ? "text-[#2C82FF] font-semibold" : "text-gray-700"
                  }`}
                  onClick={() => setActiveMenu(item.name)}
                >
                  <item.icon
                    className={`h-5 w-5 ${
                      item.name === activeMenu ? "text-[#2C82FF]" : "text-gray-700"
                    }`}
                  />
                  <span className={` text-xs ${
                    item.name === activeMenu ? "text-[#2C82FF] font-semibold" : "text-gray-700"
                  }`}>{item.name}</span>
                </Button>
              ))}
            </div>
          </div>

          {/* Community Section */}
          <div>
            <h3 className="px-3 text-sm font-medium text-gray-500">COMMUNITY</h3>
            <div className="mt-2 space-y-1">
              {navigation.community.map((item) => (
                <Button
                  key={item.name}
                  variant="ghost"
                  className={`w-full flex items-center justify-start space-x-3 ${
                    item.name === activeMenu ? "text-[#2C82FF] font-semibold" : "text-gray-700"
                  }`}
                  onClick={() => setActiveMenu(item.name)}
                >
                  <item.icon
                    className={`h-5 w-5 ${
                      item.name === activeMenu ? "text-[#2C82FF]" : "text-gray-700"
                    }`}
                  />
                  <span className={`text-xs ${
                    item.name === activeMenu ? "text-[#2C82FF] font-semibold" : "text-gray-700"
                  }`}>{item.name}</span>
                </Button>
              ))}
            </div>
          </div>
        </div>
      </nav>
      {/* Social Media Icons */}
      <div className="p-6 flex gap-4">
        <Button variant="ghost" size="icon">
          <Twitter className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="icon">
          <Facebook className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="icon">
          <Instagram className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="icon">
          <Youtube className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
