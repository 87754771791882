import React from 'react';
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Screener } from '../../types/screener';

interface ScreenerCategoryProps {
  title: string;
  screeners: Screener[];
}

export const ScreenerCategory: React.FC<ScreenerCategoryProps> = ({ title, screeners }) => {
  const getTitle = (title: string) => {
    switch (title) {
      case 'fundamental-screener':
        return 'Fundamental Screeners';
      case 'top-screeners':
        return 'Top Screeners';
      case 'intraday':
        return 'Intraday Screeners';
      default:
        return title; // Return the original title if no match
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">{getTitle(title)}</h3>
        <button className="text-gray-400">
          <ChevronDown className="w-5 h-5" />
        </button>
      </div>
      <div className="space-y-3">
        {screeners.map((screener) => (
          <Link
            key={screener._id}
            to={`/screener/${screener.slug}`}
            state={{ screener }}
            className="block text-gray-600 hover:text-blue-600 text-sm transition-colors"
          >
            {screener.screenName}
          </Link>
        ))}
      </div>
    </div>
  );
};
