import React from "react";
import { Bell } from "lucide-react";
import { Button } from "../../../components/ui/button";
import ProfileDropdown from "../../../components/ProfileDown";
import SearchBar from "../../../components/SearchBar";

const Header: React.FC = () => {
  return (
    <header className="border-b px-6 py-3 bg-white flex items-center justify-between">
      <div className="flex items-center flex-1 max-w-4xl">
        {/* Make SearchBar take the full width of its container */}
        <SearchBar
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2C82FF]"
        />
      </div>
      <div className="flex items-center gap-4">
        <Button variant="ghost" size="icon">
          <Bell className="h-5 w-5" />
        </Button>
        <ProfileDropdown />
      </div>
    </header>
  );
};

export default Header;
