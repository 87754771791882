import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TrendingUp } from 'lucide-react';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { API_BASE_URL } from '../../../utils/constants';

// Define types
type MoodData = {
  _id: string;
  mood: string;
};

type MoodCategory = 'EXTREME_FEAR' | 'FEAR' | 'GREED' | 'EXTREME_GREED';

// Configurable mood thresholds
const MOOD_THRESHOLDS = {
  EXTREME_FEAR: 30,
  FEAR: 50,
  GREED: 70,
  // Anything above GREED is considered EXTREME_GREED
};

// Mood descriptions
const MOOD_DESCRIPTIONS: Record<MoodCategory, string> = {
  EXTREME_FEAR: "MMI is in the extreme fear zone. Extreme fear (<30) suggests a good time to open fresh positions, as markets are likely to be oversold and might turn upwards.",
  FEAR: "MMI is in the fear zone. Fear suggests caution but potential opportunities in the market.",
  GREED: "MMI is in the greed zone. Greed suggests potential market optimism but exercise caution.",
  EXTREME_GREED: "MMI is in the extreme greed zone. Extreme greed suggests markets might be overbought and could turn downwards."
};

const getMoodCategory = (value: number): MoodCategory => {
  if (value <= MOOD_THRESHOLDS.EXTREME_FEAR) return 'EXTREME_FEAR';
  if (value <= MOOD_THRESHOLDS.FEAR) return 'FEAR';
  if (value <= MOOD_THRESHOLDS.GREED) return 'GREED';
  return 'EXTREME_GREED';
};

const getIndicatorPosition = (value: number): string => {
  const position = Math.min(Math.max(value, 0), 100);
  return `calc(${position}% - 8px)`; // Subtracting half of the indicator width (16px / 2)
};

export default function MarketMoodIndex() {
  const [moodData, setMoodData] = useState<MoodData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMoodData = async () => {
      try {
        const response = await axios.get<{ status: string; data: MoodData[] }>(`${API_BASE_URL}/api/v1/market/mood`);
        setMoodData(response.data.data[0]);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch market mood data');
        setLoading(false);
      }
    };

    fetchMoodData();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <div>{error}</div>;
  if (!moodData) return null;

  const moodValue = parseFloat(moodData.mood);
  const moodCategory = getMoodCategory(moodValue);

  return (
    <div className="max-w-4xl mx-auto px-6 py-12 text-center bg-white mt-4 mb-4 rounded-lg">
      <h1 className="text-4xl font-bold text-gray-800 mb-2">
        Market Mood Index
      </h1>
      <p className="text-xl text-gray-600 mb-12">
        Know what's the market sentiment today
      </p>

      {/* Gauge Container */}
      <div className="relative w-full h-4 mb-8">
        {/* Gauge Background */}
        <div className="absolute w-full h-full rounded-full overflow-hidden flex">
          <div className="bg-green-500" style={{ width: `${MOOD_THRESHOLDS.EXTREME_FEAR}%` }} /> {/* Extreme Fear */}
          <div className="bg-orange-400" style={{ width: `${MOOD_THRESHOLDS.FEAR - MOOD_THRESHOLDS.EXTREME_FEAR}%` }} /> {/* Fear */}
          <div className="bg-orange-600" style={{ width: `${MOOD_THRESHOLDS.GREED - MOOD_THRESHOLDS.FEAR}%` }} /> {/* Greed */}
          <div className="bg-[#D62121]" style={{ width: `${100 - MOOD_THRESHOLDS.GREED}%` }} /> {/* Extreme Greed */}
        </div>
        
        {/* Indicator */}
        <div 
          className={`absolute w-4 h-4 rounded-full -mt-1 -ml-2 border-2 border-white ${
            moodCategory === 'EXTREME_FEAR' ? 'bg-green-500' :
            moodCategory === 'FEAR' ? 'bg-orange-400' :
            moodCategory === 'GREED' ? 'bg-orange-600' : 'bg-red-500'
          }`}
          style={{ 
            left: getIndicatorPosition(moodValue),
            transition: 'left 0.3s ease-in-out, background-color 0.3s ease-in-out'
          }}
        />
      </div>

      {/* Labels */}
      <div className="flex justify-between text-sm mb-16">
        <span className="text-green-500">Extreme fear</span>
        <span className="text-orange-400">Fear</span>
        <span className="text-orange-600">Greed</span>
        <span className="text-red-500">Extreme Greed</span>
      </div>

      {/* Current Value */}
      <div className="mb-8">
        <div className={`inline-block rounded-full p-4 mb-4 ${
          moodCategory === 'EXTREME_FEAR' ? 'bg-green-500' :
          moodCategory === 'FEAR' ? 'bg-orange-400' :
          moodCategory === 'GREED' ? 'bg-orange-600' : 'bg-red-500'
        }`}>
          <TrendingUp className="w-6 h-6 text-white" />
        </div>
        <h2 className="text-4xl font-bold mb-4">
          {moodValue.toFixed(2)} {moodCategory.replace('_', ' ').toLowerCase()}
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          {MOOD_DESCRIPTIONS[moodCategory]}
        </p>
      </div>
    </div>
  );
}

