import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, User as FirebaseUser } from "firebase/auth";
import { UserData } from "../types/user";
import { useUserContext } from "../context/UserContext";
import { API_BASE_URL } from '../utils/constants';

// Initialize Firebase (replace with your config)
const firebaseConfig = {
  apiKey: "AIzaSyBi3aqs5QFGnzqd3bnKWg2MyX9x7ZC3T3E",
  authDomain: "screener-market1.firebaseapp.com",
  projectId: "screener-market1",
  storageBucket: "screener-market1.firebasestorage.app",
  messagingSenderId: "733153551852",
  appId: "1:733153551852:web:2d21365a69541dc9940cc9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

interface LoginDialogProps {
  onClose: () => void;
}

const LoginDialog: React.FC<LoginDialogProps> = ({ onClose }) => {
  const { setUser } = useUserContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [termsContent, setTermsContent] = useState("");
  const [privacyContent, setPrivacyContent] = useState("");
  const navigate = useNavigate();
  const fetchTermsAndPrivacy = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/document/tnc`);
      setTermsContent(response.data.data.terms);
      setPrivacyContent(response.data.data.privacyPolicy);
    } catch (error) {
      console.error("Error fetching terms and privacy policy:", error);
      toast.error("Failed to load Terms and Privacy Policy.");
    }
  };

  const formatUserData = (data: any): UserData => {
    return {
      _id: data._id || data.uid || "",
      name: data.name || `${data.firstName || ""} ${data.lastName || ""}`.trim(),
      firstName: data.firstName || data.displayName?.split(" ")[0] || "",
      lastName: data.lastName || data.displayName?.split(" ").slice(1).join(" ") || "",
      email: data.email || "",
      mobile: data.mobile || "",
      subscription: data.subscription || "free",
      validity: data.validity || "",
      isAdmin: data.isAdmin || false,
      isSubscribed: data.isSubscribed || false,
      photoURL: data.photoURL || "",
    };
  };

  const storeUserData = (userData: UserData) => {
    localStorage.setItem("isUserLogin", "true");
    localStorage.setItem("userData", JSON.stringify(userData));
    console.log("Stored user data:", userData); // Log for testing
  };

  const handleSuccessfulLogin = (userData: UserData) => {
    setUser(userData); 
    storeUserData(userData);
    toast.success("Login successful!");
    navigate("/dashboard");
    window.location.reload();
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const handleLogin = async () => {
    if (!email || !password) {
      toast.error("Please fill in all fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/authentication/login`, {
        username: email,
        password: password,
      });

      if (response.data.status === "OK") {
        const userData = formatUserData(response.data.data[0]);
        handleSuccessfulLogin(userData);
      } else {
        toast.error("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user as FirebaseUser;

      const userData = formatUserData({
        _id: user.uid,
        name: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      });

      handleSuccessfulLogin(userData);
    } catch (error) {
      console.error("Google Sign-In error:", error);
      toast.error("Google Sign-In failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const openTermsDialog = () => {
    setOpenDialog(true);
    fetchTermsAndPrivacy();
  };

  const openPrivacyDialog = () => {
    setOpenDialog(true);
    fetchTermsAndPrivacy();
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-md w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-3/5 flex relative">
        {/* Left Section - Image */}
        <div className="w-1/2 bg-blue-50 flex justify-center items-center">
          <img
            src="/images/stockmarket.jpg"
            alt="Market Screener"
            className="w-full h-full object-fill"
          />
        </div>

        {/* Right Section - Login UI */}
        <div className="w-1/2 p-8">
          <div className="absolute top-4 right-4">
            <button onClick={onClose} className="text-gray-500">
              X
            </button>
          </div>
          <div className="p-2"></div>
          <div className="mb-6 text-center">
            <h2 className="text-2xl text-black font-semibold">
              Welcome to Market Screener
            </h2>
          </div>

          <div className="mb-4">
            <button 
              className="w-full bg-white border-2 border-gray-300 text-black py-2 px-4 rounded-md flex items-center justify-center space-x-2"
              onClick={handleGoogleSignIn}
              disabled={loading}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
              </svg>
              <span>{loading ? "Signing in..." : "Continue with Google"}</span>
            </button>
          </div>
          <div className="text-center text-black mb-4">OR</div>
          <div>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Enter email or phone"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 rounded-md border border-gray-300 text-black font-semibold"
              />
            </div>
            <div className="mb-4">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 rounded-md border border-gray-300 text-black"
              />
            </div>
            <div className="mb-4">
              <button
                className="w-full bg-blue-500 text-white py-2 rounded-md"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? "Logging in..." : "Login"}
              </button>
            </div>
          </div>

          <div className="text-center mt-4">
            <span className="text-sm text-gray-500">
              By continuing you agree to{" "}
              <span
                className="text-blue-500 cursor-pointer"
                onClick={openTermsDialog}
              >
                Terms and Conditions
              </span>{" "}
              and{" "}
              <span
                className="text-blue-500 cursor-pointer"
                onClick={openPrivacyDialog}
              >
                Privacy Policy
              </span>
            </span>
          </div>
        </div>
      </div>

      {/* Terms and Conditions / Privacy Policy Dialog */}
      {openDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-md w-11/12 sm:w-4/6 md:w-2/3 lg:w-1/2 p-8 relative">
            <button
              onClick={closeDialog}
              className="absolute top-4 right-4 text-gray-500"
            >
              X
            </button>
            <div>
              <h2 className="text-2xl font-semibold mb-4">
                {termsContent ? "Terms and Conditions" : "Privacy Policy"}
              </h2>
              <div
                className="text-sm text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: termsContent || privacyContent,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginDialog;

