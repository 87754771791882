import React from 'react'

interface DrawerContentProps {
  title: string
}

const DrawerContent: React.FC<DrawerContentProps> = ({ title }) => {
  return (
    <div className="h-full flex items-center justify-center">
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>
        <p className="text-gray-500">Coming soon...</p>
      </div>
    </div>
  )
}

export default DrawerContent

