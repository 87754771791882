import React, { useState } from "react"
import { Trash2, Plus, Copy, RotateCcw } from 'lucide-react'
import { Button } from "../../../components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select"

const indicators = ["Latest", "High", "Low", "RSI", "MACD", "Volume"]
const comparisons = ["Is greater than", "Is less than", "Equals", "Not equals"]
const timeframes = ["1 Day ago", "1 Week ago", "1 Month ago", "1 Year ago"]
const values = ["Close", "Open", "High", "Low"]


interface FilterRow {
  id: number
  indicator: string
  comparison: string
  timeframe: string
  value: string
}

const dummyData = [
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+2.03 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-0.24 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+5.02 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-0.15 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-1.03%", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-2.00 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+2.03 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-0.24 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+5.02 %", last: "Lorem ipsum dolor" },
  { date: "23/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-0.15 %", last: "Lorem ipsum dolor" },
  { date: "24/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+1.75 %", last: "Lorem ipsum dolor" },
  { date: "24/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-0.89 %", last: "Lorem ipsum dolor" },
  { date: "24/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+3.12 %", last: "Lorem ipsum dolor" },
  { date: "25/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-1.45 %", last: "Lorem ipsum dolor" },
  { date: "25/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+2.67 %", last: "Lorem ipsum dolor" },
  { date: "25/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-0.33 %", last: "Lorem ipsum dolor" },
  { date: "26/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+1.98 %", last: "Lorem ipsum dolor" },
  { date: "26/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-0.76 %", last: "Lorem ipsum dolor" },
  { date: "26/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "+4.21 %", last: "Lorem ipsum dolor" },
  { date: "27/09/2020", ticker: "Lorem ipsum dolor sit amet gala dis angis", change: "-2.11 %", last: "Lorem ipsum dolor" },
]

const ITEMS_PER_PAGE = 10

const ScreenerContent: React.FC = () => {
  const [filterRows, setFilterRows] = useState<FilterRow[]>([
    { id: 1, indicator: "Latest", comparison: "Is greater than", timeframe: "1 Day ago", value: "Close" },
    { id: 2, indicator: "RSI", comparison: "Is greater than", timeframe: "1 Day ago", value: "70" },
  ])
  const [currentPage, setCurrentPage] = useState(1)
  const [activeTab, setActiveTab] = useState<"result" | "backtest">("result")

  const addRow = () => {
    const newRow = {
      id: Date.now(),
      indicator: "Latest",
      comparison: "Is greater than",
      timeframe: "1 Day ago",
      value: "Close",
    }
    setFilterRows([...filterRows, newRow])
  }

  const deleteRow = (id: number) => {
    setFilterRows((prevRows) => prevRows.filter((row) => row.id !== id))
  }

  const totalPages = Math.ceil(dummyData.length / ITEMS_PER_PAGE)
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE
  const currentItems = dummyData.slice(startIndex, endIndex)

  const renderPaginationButtons = () => {
    const buttons = []
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
        buttons.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i
                ? "bg-blue-600 text-white"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            {i}
          </button>
        )
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        buttons.push(
          <span key={i} className="px-3 py-1">
            ...
          </span>
        )
      }
    }
    return buttons
  }

  return (
    <div className="">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold">Create Stock Screener</h1>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Search for Instruments :</span>
                <Select defaultValue="sp500">
                  <SelectTrigger className="w-[160px]">
                    <SelectValue placeholder="Select market" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="sp500">S & P 500</SelectItem>
                    <SelectItem value="nasdaq">BSE</SelectItem>
                    <SelectItem value="dow">NSE</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <Button variant="outline" className="text-[#22C55E] border-[#22C55E] hover:bg-[#22C55E] hover:text-white">Save</Button>
            </div>
          </div>

          <div className="space-y-4 mb-6">
            {filterRows.map((row, index) => (
              <div key={row.id} className="flex items-center gap-4">
                <Button variant="ghost" size="icon" onClick={() => deleteRow(row.id)}>
                  <Trash2 className="h-4 w-4 text-gray-400" />
                </Button>
                <Select defaultValue={row.indicator}>
                  <SelectTrigger className="w-[120px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {indicators.map((indicator) => (
                      <SelectItem key={indicator} value={indicator.toLowerCase()}>
                        {indicator}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Select defaultValue={row.comparison}>
                  <SelectTrigger className="w-[160px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {comparisons.map((comparison) => (
                      <SelectItem key={comparison} value={comparison.toLowerCase()}>
                        {comparison}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Select defaultValue={row.timeframe}>
                  <SelectTrigger className="w-[140px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {timeframes.map((timeframe) => (
                      <SelectItem key={timeframe} value={timeframe.toLowerCase()}>
                        {timeframe}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Select defaultValue={row.value}>
                  <SelectTrigger className="w-[120px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {values.map((value) => (
                      <SelectItem key={value} value={value.toLowerCase()}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button variant="ghost" size="icon">
                  <Copy className="h-4 w-4 text-gray-400" />
                </Button>
                <Button variant="ghost" size="icon">
                  <RotateCcw className="h-4 w-4 text-gray-400" />
                </Button>
                {index === filterRows.length - 1 && (
                  <Button variant="ghost" size="icon" onClick={addRow}>
                    <Plus className="h-4 w-4 text-[#2C82FF]" />
                  </Button>
                )}
                {index < filterRows.length - 1 && (
                  <span className="text-sm font-medium">And</span>
                )}
              </div>
            ))}
          </div>

          <div className="flex justify-end mb-6">
            <Button variant="outline" className="bg-[#2C82FF] text-white hover:bg-[#2C82FF]/90">
              Run Screener
            </Button>
          </div>

          <div className="flex justify-between items-center mb-6">
            <div className="flex space-x-2 p-2 bg-slate-100 rounded-lg">
              <button
                onClick={() => setActiveTab("result")}
                className={`px-4 py-2 rounded-md ${
                  activeTab === "result"
                    ? "bg-blue-100 text-blue-600"
                    : "text-gray-600 hover:bg-gray-50"
                }`}
              >
                Result
              </button>
              <button
                onClick={() => setActiveTab("backtest")}
                className={`px-4 py-2 rounded-md ${
                  activeTab === "backtest"
                    ? "bg-blue-100 text-blue-600"
                    : "text-gray-600 hover:bg-gray-50"
                }`}
              >
                Backtest
              </button>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Refresh Interval :</span>
                <Select defaultValue="10">
                  <SelectTrigger className="w-[200px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="10">Every 10 seconds</SelectItem>
                    <SelectItem value="30">Every 30 seconds</SelectItem>
                    <SelectItem value="60">Every minute</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <Button variant="outline" className="text-[#22C55E] border-[#22C55E] hover:bg-[#22C55E] hover:text-white">
                Download CSV
              </Button>
            </div>
          </div>

          {activeTab === "result" && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ticker
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Change
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentItems.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.date}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600">
                        {item.ticker}
                      </td>
                      <td className={`px-6 py-4 whitespace-nowrap text-sm ${item.change.startsWith('+') ? 'text-[#22C55E]' : 'text-[#EF4444]'}`}>
                        {item.change}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {item.last}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {activeTab === "backtest" && (
            <div className="p-4 text-center text-gray-500">
              Coming soon...
            </div>
          )}

          {totalPages > 1 && (
            <div className="flex items-center justify-between mt-4 px-6 py-3 bg-gray-50">
              <div className="text-sm text-gray-600">
                Showing {startIndex + 1}-{Math.min(endIndex, dummyData.length)} of {dummyData.length}
              </div>
              <div className="flex space-x-2">{renderPaginationButtons()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ScreenerContent

