import React, { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Pause, Play } from 'lucide-react';
import { useScreenerData } from '../hooks/useScreenerData';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { ErrorMessage } from '../components/ui/ErrorMessage';
import { RefreshIntervalDropdown } from '../components/screener/RefreshIntervalDropdown';
import { BacktestTab } from '../components/screener/BacktestTab';
import { downloadCSV } from '../../../utils/csvExport';

const ITEMS_PER_PAGE = 10;

export const ScreenerDetails: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { data, loading, error } = useScreenerData();
  const [activeTab, setActiveTab] = useState<'result' | 'backtest'>('result');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  
  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error.message} />;
  
  const screener = data.find((s) => s.slug === slug);
  if (!screener) return <ErrorMessage message="Screener not found" />;

  const totalPages = Math.ceil(screener.details.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = screener.details.slice(startIndex, endIndex);

  const handleRefreshIntervalChange = (seconds: number) => {
    console.log(`Refresh interval set to ${seconds} seconds`);
  };

  const handleDownloadCSV = () => {
    const timestamp = new Date().toISOString().split('T')[0];
    const filename = `${screener.slug}-${timestamp}.csv`;
    downloadCSV(screener.details, filename);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        buttons.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i
                ? 'bg-blue-600 text-white'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            {i}
          </button>
        );
      } else if (
        i === currentPage - 2 ||
        i === currentPage + 2
      ) {
        buttons.push(
          <span key={i} className="px-3 py-1">
            ...
          </span>
        );
      }
    }
    return buttons;
  };
  const getTitle = (title: string) => {
    switch (title) {
      case 'fundamental-screener':
        return 'Fundamental Screener';
      case 'top-screeners':
        return 'Active Today';
      case 'intraday':
        return 'Intraday Screener';
      default:
        return title; // Return the original title if no match
    }
  };
  return (
    <div className="container mx-auto px-4 sm:px-6 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-6">
          <Link to="/" className="text-blue-600 hover:text-blue-800 mb-4 inline-block">
            ← Back to Screeners
          </Link>
          {/* <h1 className="text-2xl font-semibold mb-6">{getTitle(screener.uiCategoryName)}</h1> */}
          <h1 className="text-2xl font-semibold mb-6">{screener.screenName}</h1>
          {/* Controls */}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 mb-6">
            <div className="flex space-x-2 p-2 bg-slate-100 rounded-lg">
              <button
                onClick={() => setActiveTab('result')}
                className={`px-4 py-2 rounded-md ${
                  activeTab === 'result'
                    ? 'bg-blue-100 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Result
              </button>
              <button
                onClick={() => setActiveTab('backtest')}
                className={`px-4 py-2 rounded-md ${
                  activeTab === 'backtest'
                    ? 'bg-blue-100 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Backtest
              </button>
            </div>
            
            <div className="flex items-center space-x-4 w-full sm:w-auto">
              <div className="flex items-center space-x-2">
                <span className="text-gray-600">Refresh Interval:</span>
                <RefreshIntervalDropdown onIntervalChange={handleRefreshIntervalChange} />
              </div>
              
              <button 
                onClick={() => setIsPaused(!isPaused)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded"
              >
                {isPaused ? (
                  <Play className="w-5 h-5" />
                ) : (
                  <Pause className="w-5 h-5" />
                )}
              </button>
              
              <button 
                onClick={handleDownloadCSV}
                className="px-4 py-2 bg-green-50 text-green-600 hover:bg-green-100 rounded ml-2"
              >
                Download CSV
              </button>
            </div>
          </div>

          {activeTab === 'result' ? (
            <>
              {/* Table */}
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock Name</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Symbol</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% Change</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Volume</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentItems.map((detail, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{startIndex + index + 1}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{detail.stockName}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600">{detail.symbol}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">+{detail.chg}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{detail.price}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{detail.volume}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex items-center justify-between mt-4 px-6 py-3 bg-gray-50">
                  <div className="text-sm text-gray-600">
                    Showing {startIndex + 1}-{Math.min(endIndex, screener.details.length)} of {screener.details.length}
                  </div>
                  <div className="flex space-x-2">
                    {renderPaginationButtons()}
                  </div>
                </div>
              )}
            </>
          ) : (
            <BacktestTab />
          )}
        </div>
      </div>
    </div>
  );
};