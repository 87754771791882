import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/Layout";
import StockScreener from "./pages/Home/pages/StockScreener";


import Bonds from "./components/screeners/BondsScreener";
import Commodities from "./components/screeners/CommoditiesScreener";
import News from "./components/screeners/News";
import { ScreenerDetails } from "./pages/Home/pages/ScreenerDetails";
import MarketMoodIndex from "./pages/Home/pages/MarketMood";
import { UserProvider } from "./context/UserContext";
import Dashboard from "./pages/Dashboard/Dashboard";
import { HighLowScreener } from './components/screeners/HighLowScreener';
import { Ipos } from './components/screeners/IPOsScreener';
import SingleStockDetails from './pages/Home/pages/SingleStockDetails';

const App: React.FC = () => {
  return (
    <UserProvider>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<StockScreener />} />
            <Route path="52highlow" element={<HighLowScreener />} />
            <Route path="ipos" element={<Ipos />} />
            <Route path="bonds" element={<Bonds />} />
            <Route path="commodities" element={<Commodities />} />
            <Route path="news" element={<News />} />
            <Route path="marketMood" element={<MarketMoodIndex />} />
            <Route path="/screener/:slug" element={<ScreenerDetails />} />
            <Route path="/stock/:code" element={<SingleStockDetails />} />
          </Route>
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
