import { useState, useEffect } from 'react';
import { ScreenerResponse } from '../types/screener';
import { API_BASE_URL } from '../../../utils/constants';


export const useScreenerData = () => {
  const [data, setData] = useState<ScreenerResponse['data']>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/screen/getAllScreeners`);
        const json: ScreenerResponse = await response.json();
        setData(json.data);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch data'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};