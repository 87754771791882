'use client'

import React, { useEffect, useState } from "react"

export interface StockData {
  ticker: string
}

const EmbedWidget: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [stockData, setStockData] = useState<StockData[]>([])

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
     
      try {
        // In a real scenario, you might fetch this data from an API
        // For now, we'll use a static list of tickers
        const data: StockData[] = [
          { ticker: "RELIANCE" },  // Corrected ticker for Reliance Industries
          { ticker: "HDFCBANK" },  // Corrected ticker for HDFC Bank
          { ticker: "TATAMOTORS" },
          { ticker: "SBIN" },
        ]
        setStockData(data)
      } catch (err) {
        console.error("Failed to load stock data:", err)
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    const script = document.createElement("script")
    script.src = "https://www.gateway-tt.in/assets/embed.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  if (isLoading) {
    return <div className="text-center py-8 font-bold">Loading stock data...</div>
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {stockData.map((stock, index) => {
        const encodedOrders = encodeURIComponent(
          JSON.stringify([{ ticker: stock.ticker }])
        )

        return (
          <div key={index} className="border border-gray-200 rounded-lg overflow-hidden min-h-[150px]">
            <div
              className="sc-embed w-full h-full"
              data-width="100%"
              data-height="100%"
              data-orders={encodedOrders}
              data-cardsize="small"
              data-withtt="false"
              data-withsearch="false"
            >
              <p className="p-4">Loading widget for {stock.ticker}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default EmbedWidget
