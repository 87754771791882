import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface StockData {
  id: number;
  name: string;
  code: string;
  lp: string;
  ch: string;
  chp: string;
}

interface SearchBarProps {
  placeholder?: string;
  width?: string;
  className?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ 
  placeholder = "Search for stocks, indicator, pattern screener",
  className = ""
}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<StockData[]>([]);
  const [masterData, setMasterData] = useState<StockData[] | null>(null);

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const response = await fetch(
          "https://api.screeners.market/api/v1/market/masterData"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result: { status: string; data: [{ data: StockData[] }] } = await response.json();
        setMasterData(result.data[0].data);
      } catch (error) {
        console.error("Error fetching master data:", error);
      }
    };

    fetchMasterData();
  }, []);

  useEffect(() => {
    if (masterData && searchTerm) {
      const filteredResults = masterData.filter((stock) => {
        return (
          stock.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          stock.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setSearchResults(filteredResults.slice(0, 10)); // Limit to 10 results
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, masterData]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStockSelect = (stock: StockData) => {
    setSearchTerm(""); // Clear search term
    setSearchResults([]); // Clear search results
    navigate(`/stock/${stock.code}`); // Navigate to stock details page
  };

  return (
    <div className="relative flex-1">
      <input
        type="text"
        placeholder={placeholder}
        className={`w-full px-4 py-2 rounded bg-white/20 placeholder-black focus:outline-none ${className}`}
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Search className="absolute right-3 top-2.5 w-5 h-5 text-blue/70" />
      {searchResults.length > 0 && searchTerm && (
        <ul className="absolute top-full left-0 w-full bg-white rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
          {searchResults.map((stock) => (
            <li
              key={stock.id}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-800"
              onClick={() => handleStockSelect(stock)}
            >
              {stock.name} ({stock.code})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
