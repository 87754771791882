import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#1a2634] text-white py-8 sm:py-12">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="flex flex-col lg:flex-row justify-between items-start">
          <div className="mb-8 lg:mb-0">
            <h2 className="text-2xl font-bold mb-6">
              Market<span className="font-normal">Screeners</span>
            </h2>
            <div className="flex flex-col space-y-2 text-gray-400">
              <span>MarketScreener.net</span>
              <span>support@marketscreener.net</span>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-12">
            <div className="flex flex-col space-y-2">
              <Link to="/" className="hover:text-gray-300">
                Home
              </Link>
              <Link to="/products" className="hover:text-gray-300">
                Products
              </Link>
              <Link to="/company" className="hover:text-gray-300">
                Company
              </Link>
              <Link to="/blog" className="hover:text-gray-300">
                Blog
              </Link>
            </div>
            <div className="flex space-x-4">
              {/* Social media icons */}
              {/* ... (keep the existing SVG icons) ... */}
            </div>
          </div>
        </div>
        <div className="text-center text-gray-400 mt-8 sm:mt-12">
          © 2024 MarketScreener.net All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;

