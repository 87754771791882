import React from "react";
import clsx from "clsx";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "default" | "ghost" | "secondary" | "outline";
  size?: "default" | "sm" | "lg" | "icon";
  className?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = "default", size = "default", className, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "font-medium rounded-md focus:outline-none ]",
          {
            // Variant styles
            "bg-gray-100 text-black hover:bg-gray-200": variant === "default",
            "bg-transparent text-gray-600 hover:bg-gray-100": variant === "ghost",
            "bg-blue-500 text-white hover:bg-blue-600": variant === "secondary",
            "bg-[#1490E4] hover:bg-[#0292f1] text-white px-4 py-2 rounded w-full sm:w-auto": variant === "outline",

            // Size styles
            "px-4 py-2 text-sm": size === "default",
            "px-3 py-1 text-xs": size === "sm",
            "px-5 py-3 text-lg": size === "lg",
            "p-2": size === "icon",
          },
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";


