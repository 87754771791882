"use client";

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Search, Bell } from 'lucide-react';
import ProfileDropdown from "./ProfileDown";

interface StockData {
  id: number;
  name: string;
  code: string;
  lp: string;
  ch: string;
  chp: string;
}

interface MasterDataResponse {
  status: string;
  data: [{ data: StockData[] }];
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<StockData[]>([]);
  const [masterData, setMasterData] = useState<StockData[] | null>(null);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const parsedData = JSON.parse(userData);
      setUserName(parsedData.firstName);
    }
  }, []);

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const response = await fetch(
          "https://api.screeners.market/api/v1/market/masterData"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result: MasterDataResponse = await response.json();
        setMasterData(result.data[0].data);
      } catch (error) {
        console.error("Error fetching master data:", error);
      }
    };

    fetchMasterData();
  }, []);

  useEffect(() => {
    if (masterData && searchTerm) {
      const filteredResults = masterData.filter((stock) => {
        return (
          stock.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          stock.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setSearchResults(filteredResults.slice(0, 10)); // Limit to 10 results
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, masterData]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStockSelect = (stock: StockData) => {
    setSearchTerm(""); // Clear search term
    setSearchResults([]); // Clear search results
    navigate(`/stock/${stock.code}`); // Navigate to stock details page
  };

  const location = useLocation();

  return (
    <>
      <nav className="bg-[#1a2634] text-white px-4 py-2 flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 mb-2 sm:mb-0">
          <Link to="/" className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
              />
            </svg>
            <span>Stocks Screener</span>
          </Link>
          <Link to="/forex" className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
            <span>Forex Screener</span>
          </Link>
          <Link to="/crypto" className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <span>Crypto Screener</span>
          </Link>
        </div>
      </nav>

      <header className="bg-[#0093FF] text-white px-4 sm:px-6 py-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
          <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 mb-4 sm:mb-0">
            <Link to="/" className="text-2xl font-bold">
              Market<span className="font-normal">Screener</span>
            </Link>
            <div className="relative w-full sm:w-auto">
              <input
                type="text"
                placeholder="Search for stocks, indicator, pattern screener"
                className="w-full sm:w-[400px] lg:w-[600px] px-4 py-2 rounded bg-white/20 placeholder-white/70 focus:outline-none"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Search className="absolute right-3 top-2.5 w-5 h-5 text-white/70" />
              {searchResults.length > 0 && searchTerm && (
                <ul className="absolute top-full left-0 w-full sm:w-[400px] lg:w-[600px] bg-white rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
                  {searchResults.map((stock) => (
                    <li
                      key={stock.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-800"
                      onClick={() => handleStockSelect(stock)}
                    >
                      {stock.name} ({stock.code})
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Link
              to={"/marketMood"}
              className="block text-white hover:text-blue-600 text-sm transition-colors"
            >
              Market Mood Index
            </Link>
            <button className="bg-[#FFA500] px-4 py-2 rounded" onClick={() => navigate("/marketMood")}>
              Try Premium
            </button>
            <Bell className="w-6 h-6" />
            <ProfileDropdown />
          </div>
        </div>

        <nav className="flex flex-wrap gap-4">
          <Link
            to="/"
            className={`hover:text-white/80 pb-2 ${
              location.pathname === "/" ? "border-b-4 border-[#FFA500]" : ""
            }`}
          >
            Explore Screeners
          </Link>
          <Link
            to="/52highlow"
            className={`hover:text-white/80 pb-2 ${
              location.pathname === "/52highlow"
                ? "border-b-4 border-[#FFA500]"
                : ""
            }`}
          >
           52 Week High & Low
          </Link>
          <Link
            to="/ipos"
            className={`hover:text-white/80 pb-2 ${
              location.pathname === "/ipos"
                ? "border-b-4 border-[#FFA500]"
                : ""
            }`}
          >
            IPO's
          </Link>
          <Link
            to="/bonds"
            className={`hover:text-white/80 pb-2 ${
              location.pathname === "/bonds"
                ? "border-b-4 border-[#FFA500]"
                : ""
            }`}
          >
            Bonds
          </Link>
         
          <Link
            to="/commodities"
            className={`hover:text-white/80 pb-2 ${
              location.pathname === "/commodities"
                ? "border-b-4 border-[#FFA500]"
                : ""
            }`}
          >
           Commodities
          </Link>
          <Link
            to="/news"
            className={`hover:text-white/80 pb-2 ${
              location.pathname === "/news"
                ? "border-b-4 border-[#FFA500]"
                : ""
            }`}
          >
           News
          </Link>
        </nav>
      </header>
    </>
  );
};

export default Header;

