import React, { useState, useEffect } from 'react';
import { User, LogOut, ChevronDown } from 'lucide-react';
import LoginDialog from './LoginDailog';
import { UserData } from '../types/user';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const ProfileDropdown: React.FC = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData) as UserData;
      setUserData(parsedUserData);
      setIsLoggedIn(true);
    }
  }, []);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const openLoginDialog = () => {
    setDropdownOpen(false);
    setLoginDialogOpen(true);
  };

  const closeLoginDialog = () => {
    setLoginDialogOpen(false);
    // Check if user data is in localStorage after login dialog is closed
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData) as UserData;
      setUserData(parsedUserData);
      setIsLoggedIn(true);
    }
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.setItem("isUserLogin", "false");
      localStorage.removeItem('userData');
      setUserData(null);
      setIsLoggedIn(false);
      setDropdownOpen(false);
      navigate('/'); 
      window.location.reload();
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  return (
    <div className="relative">
      <div 
        className="flex items-center space-x-2 cursor-pointer" 
        onClick={toggleDropdown}
      >
        <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center overflow-hidden">
          {userData?.photoURL ? (
            <img src={userData.photoURL} alt="User" className="w-full h-full object-cover" />
          ) : (
            <User size={20} />
          )}
        </div>
        {isLoggedIn && userData && (
          <span className="text-sm font-medium">{userData.name}</span>
        )}
        <ChevronDown size={20} />
      </div>
      
      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md w-48 py-2 z-10">
          {isLoggedIn ? (
            <>
              <div className="px-4 py-2 text-sm text-gray-700">
                {userData?.photoURL && (
                  <img
                    src={userData.photoURL}
                    alt="User"
                    className="w-10 h-10 rounded-full mb-2 mx-auto"
                  />
                )}
                Signed in as <br />
                <strong>{userData?.email}</strong>
              </div>
              <div className="border-t border-gray-100 my-1"></div>
              <div className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 flex items-center" onClick={handleLogout}>
                <LogOut size={16} className="mr-2" />
                Logout
              </div>
            </>
          ) : (
            <div className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" onClick={openLoginDialog}>
              Login
            </div>
          )}
          <div className="border-t border-gray-100 my-1"></div>
          <div className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
            Buy Premium
          </div>
        </div>
      )}

      {/* Login Dialog */}
      {isLoginDialogOpen && <LoginDialog onClose={closeLoginDialog} />}
    </div>
  );
};

export default ProfileDropdown;
