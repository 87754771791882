'use client'

import React, { useEffect, useState } from "react"
import EmbedWidget from '../tickerTapeEmbededCode';


export const StockInFocus: React.FC = () => {
  const [key, setKey] = useState(0)

  useEffect(() => {
   
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Refresh the component when the tab becomes visible
        setKey(prevKey => prevKey + 1)
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  return (
    <div className="w-full p-4">
      <EmbedWidget key={key} />
    </div>
  )
}

export default StockInFocus

