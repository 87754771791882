import React from "react";

const Commodities: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Commodities Screener</h1>
      <div className="bg-white rounded-lg shadow-sm p-6">
        <p className="text-gray-600">
          This is a placeholder for the Commodities. Here you would
          typically find various technical indicators and their analysis.
        </p>
      </div>
    </div>
  );
};

export default Commodities;
