import React from "react";
import { ScreenerHeader } from "../components/screener/ScreenerHeader";
import { ScreenerList } from "../components/screener/ScreenerList";
import { useScreenerData } from "../hooks/useScreenerData";
import { LoadingSpinner } from "../components/ui/LoadingSpinner";
import { ErrorMessage } from "../components/ui/ErrorMessage";

const StockScreener: React.FC = () => {
  const { data, loading, error } = useScreenerData();

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error.message} />;

  const categories = Array.from(
    new Set(data.map((screener) => screener.uiCategoryName))
  ).map((categoryName) => ({
    name: categoryName,
    screeners: data.filter(
      (screener) => screener.uiCategoryName === categoryName
    ),
  }));

  return (
    <main className="container mx-auto px-4 sm:px-6 py-8">

      <ScreenerHeader />
      {/* fundamental,intrady,top screenersection */}
      <ScreenerList categories={categories} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
          <p className="text-gray-600 leading-relaxed">
            Get access to advanced stock screening tools and real-time market
            data. Our platform helps you make informed investment decisions with
            comprehensive market analysis and stock insights.
          </p>
        </div>

        <div
          onClick={() => console.log("Card clicked!")}
          className="relative bg-gradient-to-r from-[#0062FF] to-[#57b1f1] text-white rounded-lg shadow-sm p-4 sm:p-6 cursor-pointer hover:shadow-md transition-shadow duration-300 overflow-hidden"
        >
          <h3 className="text-xl font-semibold mb-2">
            What are you waiting for? Find more!
          </h3>
          <p className="mb-4">Try premium and get exclusive market benefits.</p>
          <button className="bg-white text-[#0093FF] px-4 py-2 rounded w-full sm:w-auto">
            Try Premium Now!
          </button>

          {/* Decorative Circle */}
          <div className="absolute bottom-0 right-0 w-[150px] h-[150px] bg-[#0078FF] opacity-25 rounded-full translate-x-1/4 translate-y-1/4"></div>
        </div>
      </div>
    </main>
  );
};
export default StockScreener;
