import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { ErrorMessage } from '../components/ui/ErrorMessage';


interface StockData {
  id: number;
  name: string;
  code: string;
  lp: string;
  ch: string;
  chp: string;
  // Add more fields as needed
}

const SingleStockDetails: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const [stockData, setStockData] = useState<StockData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const response = await fetch(`https://api.screeners.market/api/v1/market/stock/${code}`);
        if (!response.ok) {
          throw new Error('Failed to fetch stock data');
        }
        const data = await response.json();
        setStockData(data.data);
      } catch (err) {
        setError('An error occurred while fetching stock data');
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, [code]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!stockData) return <ErrorMessage message="No stock data available" />;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">{stockData.name} ({stockData.code})</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">Last Price</p>
            <p className="text-2xl font-semibold">{stockData.lp}</p>
          </div>
          <div>
            <p className="text-gray-600">Change</p>
            <p className={`text-2xl font-semibold ${parseFloat(stockData.ch) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              {stockData.ch} ({stockData.chp}%)
            </p>
          </div>
          {/* Add more stock details here */}
        </div>
      </div>
      {/* You can add more sections here, such as charts, company info, etc. */}
    </div>
  );
};

export default SingleStockDetails;

