"use client";

import React, { useState, useEffect } from "react";
import { Pause, Play } from "lucide-react";
import { LoadingSpinner } from "../../pages/Home/components/ui/LoadingSpinner";
import { ErrorMessage } from "../../pages/Home/components/ui/ErrorMessage";
import { download52WeekHighLowCSV, downloadCSV } from "../../utils/csvExport";
import { RefreshIntervalDropdown } from "../../pages/Home/components/screener/RefreshIntervalDropdown";

const ITEMS_PER_PAGE = 10;

interface StockData {
  ticker: string;
  company: string;
  price: number;
  "52_week_high"?: number;
  "52_week_low"?: number;
}

interface HighLowData {
  BSE_52WeekHighLow: {
    high52Week: StockData[];
    low52Week: StockData[];
  };
  NSE_52WeekHighLow: {
    high52Week: StockData[];
    low52Week: StockData[];
  };
}

export const HighLowScreener: React.FC = () => {
  const [data, setData] = useState<HighLowData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [activeTab, setActiveTab] = useState<"high" | "low">("high");
  const [activeExchange, setActiveExchange] = useState<"BSE" | "NSE">("BSE");
  const [currentPage, setCurrentPage] = useState(1);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.screeners.market/api/v1/market/highlow"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result.data[0]);
      setLoading(false);
    } catch (error) {
      setError(error as Error);
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!data) return <ErrorMessage message="No data available" />;

  const currentData =
    data[`${activeExchange}_52WeekHighLow`][
      activeTab === "high" ? "high52Week" : "low52Week"
    ];
  const totalPages = Math.ceil(currentData.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = currentData.slice(startIndex, endIndex);

  const handleRefreshIntervalChange = (seconds: number) => {
    console.log(`Refresh interval set to ${seconds} seconds`);
    // Implement refresh logic here
  };

  const handleDownloadCSV = () => {
    const timestamp = new Date().toISOString().split("T")[0];
    const filename = `${activeExchange}_${activeTab}_52Week-${timestamp}.csv`;
    download52WeekHighLowCSV(currentData, filename);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        buttons.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i
                ? "bg-blue-600 text-white"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            {i}
          </button>
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        buttons.push(
          <span key={i} className="px-3 py-1">
            ...
          </span>
        );
      }
    }
    return buttons;
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-6">
          <h1 className="text-2xl font-semibold mb-6">
            52 Week High/Low Screener
          </h1>
          {/* Controls */}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 mb-6">
            <div className="flex space-x-2 p-2 bg-slate-100 rounded-lg">
              <button
                onClick={() => setActiveTab("high")}
                className={`px-4 py-2 rounded-md ${
                  activeTab === "high"
                    ? "bg-blue-100 text-blue-600"
                    : "text-gray-600 hover:bg-gray-50"
                }`}
              >
                52 Week High
              </button>
              <button
                onClick={() => setActiveTab("low")}
                className={`px-4 py-2 rounded-md ${
                  activeTab === "low"
                    ? "bg-blue-100 text-blue-600"
                    : "text-gray-600 hover:bg-gray-50"
                }`}
              >
                52 Week Low
              </button>
            </div>

            <div className="flex items-center space-x-4 w-full sm:w-auto">
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setActiveExchange("BSE")}
                  className={`px-4 py-2 rounded-md ${
                    activeExchange === "BSE"
                      ? "bg-green-100 text-green-600"
                      : "text-gray-600 hover:bg-gray-50"
                  }`}
                >
                  BSE
                </button>
                <button
                  onClick={() => setActiveExchange("NSE")}
                  className={`px-4 py-2 rounded-md ${
                    activeExchange === "NSE"
                      ? "bg-green-100 text-green-600"
                      : "text-gray-600 hover:bg-gray-50"
                  }`}
                >
                  NSE
                </button>
              </div>
              {/* <div className="flex items-center space-x-2">
                <span className="text-gray-600">Refresh Interval:</span>
                <RefreshIntervalDropdown onIntervalChange={handleRefreshIntervalChange} />
              </div> */}

              {/* <button 
                onClick={() => setIsPaused(!isPaused)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded"
              >
                {isPaused ? (
                  <Play className="w-5 h-5" />
                ) : (
                  <Pause className="w-5 h-5" />
                )}
              </button> */}

              <button
                onClick={handleDownloadCSV}
                className="px-4 py-2 bg-green-50 text-green-600 hover:bg-green-100 rounded ml-2"
              >
                Download CSV
              </button>
            </div>
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    #
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Company
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Symbol
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {activeTab === "high" ? "52 Week High" : "52 Week Low"}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {startIndex + index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.company}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600">
                      {item.ticker}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.price.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {activeTab === "high"
                        ? item["52_week_high"]?.toFixed(2)
                        : item["52_week_low"]?.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex items-center justify-between mt-4 px-6 py-3 bg-gray-50">
              <div className="text-sm text-gray-600">
                Showing {startIndex + 1}-
                {Math.min(endIndex, currentData.length)} of {currentData.length}
              </div>
              <div className="flex space-x-2">{renderPaginationButtons()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
