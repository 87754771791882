import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const intervals = [
  { label: 'Every 10 seconds', value: 10 },
  { label: '30 seconds', value: 30 },
  { label: '1 minute', value: 60 },
  { label: '2 minutes', value: 120 },
  { label: '5 minutes', value: 300 },
];

interface RefreshIntervalDropdownProps {
  onIntervalChange: (seconds: number) => void;
}

export const RefreshIntervalDropdown: React.FC<RefreshIntervalDropdownProps> = ({ onIntervalChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(intervals[0]);

  const handleSelect = (interval: typeof intervals[0]) => {
    setSelectedInterval(interval);
    onIntervalChange(interval.value);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-1 px-3 py-1 text-blue-600 hover:bg-blue-50 rounded"
      >
        <span>{selectedInterval.label}</span>
        <ChevronDown className="w-4 h-4" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg z-10">
          <ul className="py-1">
            {intervals.map((interval) => (
              <li key={interval.value}>
                <button
                  onClick={() => handleSelect(interval)}
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {interval.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};